.video-data {
    width: 400px;
    margin: 60px auto;
}

.video-data .data .thumbnail img {
    width: 100%;
}

.video-data .data .info {
    margin-top: 10px;
}

.video-data .data .info h2 {
    font-size: 16px;
    margin-bottom: 10px;
    color:#222;
}

.video-data .data .info p {
    font-size: 13px;
    color: #555;
}

.video-data controls {
    margin-top:20px;
    display: flex;
    justify-content: space-between;
}

.video-data .controls select,
.video-data .controls button {
    width: 48%;
    height: 40px;
}

.video-data .controls button {
    width: 400px;
    border: 1px solid #888;
    color: #111;
    background-color: transparent;
    cursor: pointer;
}

.convert-again {
    width: 400px;
    background-color: red;
}