.form {
    padding: 60px 0px;
}

.form .form-element {
    width: 50%;
    margin: 0px auto;
}

.form .form-element label {
    display: block;
    margin-bottom: 5;
    padding: 10px;
}

.form .form-element input {
    width: 80%;
    padding: 10px;
    outline: none;
    font-size: 15px;
    border: 5px solid #7ed214;
    height: 50px;
    font-family: 'Roboto', sans-serif;
    -webkit-appearance: textfield;
}

.form .download-button-div button {
    padding-left: 12px;
    display: inline-block;
}

.form .download-button-div button {
    width: 100px;
    height: 50px;
    background: #7ed214;
    color: #f5f5f5;
    border: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.label-title-div {
    padding-top: 60px;
    width: 500px;
    margin: 0px auto;
}

.label-title-div label {
    display: block;
    margin-bottom: 5;
    color: rgb(67, 67, 67);
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding: 10px;
    text-align: center;
}

.asd {
    padding-left: 10px;
    display: inline-block;
}

.asd button {
    padding-left: 20px;
    padding-right: 20px;
    background: #7ed214;
    color: #f5f5f5;
    width: auto;
    height: 50px;
    font-weight: 700;
    font-size: .875rem;
    border: none;
    outline: none;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}
  
.error-ui {
    width: 45%;;
    border: 1px solid rgb(218, 218, 218);
    margin: 0px auto;
    padding: 60;
}

.label-error {
  display: block;
  padding: 20px;
  text-align: center;
}