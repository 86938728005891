* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

*,
*:after,
*:before {
  margin:0;
  padding:0;
  box-sizing:border-box;
}