.footer {
  border-top: 1px solid rgb(231, 231, 231);
  text-align: center;
  bottom: 0;
  position: fixed;
  width: 100%;
  background: white;
  margin: 0px auto;
  }

  .footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .footer li {
    display: inline-block;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }