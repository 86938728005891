.help-body {
    width: 80%;
    margin: 0px auto;
    background: white;
    color: #242424;
    text-align: left;
}

.help-body h1 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #242424;
}