.terms-body {
    width: 80%;
    margin: 0px auto;
    background: white;
    color: #242424;
    text-align: left;
}

.terms-body h1 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #242424;
}

.terms-body h1title {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #242424;
}

.terms-body h4 {
    padding-top: 10px;
    padding-bottom: 10px;
    color: #242424;
}

.terms-body .bottom-div {
    padding-bottom: 75px;
}