nav {
  border-bottom: 1px solid rgb(231, 231, 231);
  text-align: right;
  top: 0;
  width: 70%;
  background: white;
  margin: 0px auto;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 16px;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  padding: 16px 32px;
}

nav a:hover {
    box-shadow: inset 0 -2px 0 0 rgb(0, 122, 255); 
}